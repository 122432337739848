import * as React from "react";
import styled from "styled-components";
import Header from "../components/Header";

const BookPage = () => {
  return (
    <BookContainer>
      <Header />
      <Title> Book Now </Title>
    </BookContainer>
  );
};

/*--------------Styling-----------------*/

const BookContainer = styled.div`
  background-color: #fff;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: black;
  text-align: center;
`;

export default BookPage;
